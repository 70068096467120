import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'



const prod = /^gzh\./.test(location.hostname);
const apiHost = prod ? 'https://api.linkprofit.cn/': 'https://sit.api.linkprofit.cn/';

//const apiHost = prod ? 'https://test.api.linkprofit.cn': 'http://192.168.33.50';

export function getApiHost() {
  return apiHost;
}

axios.interceptors.request.use(
  config => {
    config.baseURL = apiHost + '/index.php'
    config.withCredentials = true
    const token = Vue.ls.get('ACCESS_TOKEN')
    if (token) {
      config.headers['token'] = token
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
axios.interceptors.response.use(
  response => {
    if(response.data && response.data.status == 401) {
      Vue.ls && Vue.ls.remove('ACCESS_TOKEN');
      location.href='#/login';
    }
    if (response.data && response.data.status == 403) {
      location.href='#/member';
    }   
    return response
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)


export function fetchGet (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}

export function fetchPost (url, params = {}, headers) {
    return new Promise((resolve, reject) => {
      axios.post(url, params, headers).then(res => {
        if (!res) {
          resolve({})
        } else {
          resolve(res.data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

export function postForm (url, params = {}) {
    if (params.sn === true) {
      const sn = Vue.ls.get('SN') || '';
      params.sn = sn;
    }

    return new Promise((resolve, reject) => {
      const headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
      const p = {};
      for(var i in params) {
         var value = params[i];
         if(value !== null){
           p[i] = value;
         }
      }
      axios.post(url, p, {headers}).then(res => {
        if (!res) {
          resolve({})
        } else {
          resolve(res.data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

export function uploadFile(url, file, key) {
  const formData = new FormData();
  const name = key || 'file';
  formData.append(name, file);
  return axios({
    url,
    method: 'post',
    data: formData
  });
}

export function login (data) {
  return fetchPost('/h5/admin/login', data);
}


export function logout () {
  return fetchPost('/h5/admin/logout').then(res => {
    return res;
  });
}

// 售后业务类型
export async function getAsBusinessOtps () {
  return getBusinessOtps('after');
}
// 业务类型
export async function getBusinessOtps (type='before') {
  const t = {
    after: 1,
    before: 0,
    all: '',
  }
  return postForm('/api/option/index', {
    auth: 1,
    table: 'business',
    type: t[type]
  }).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.name, o.name));
      
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 集团
export function getCropOtps () {
  const cKey = 'Crop_OPTS';
  const auth = 1;
  return getOtpList(cKey, 'crop', auth);
}
// 大区
export function getRegionOtps () {
  const cKey = 'REGION_OPTS';
  const auth = 1;
  return getOtpList(cKey, 'region', auth);
}

// 小区
export function getAreaOtps () {
  const cKey = 'AREA_OPTS';
  const auth = 1;
  return getOtpList(cKey, 'area', auth);
}
/*
export function getRegionOtps () {
  const cKey = 'REGION_OPTS';
  const list = Vue.ls.get(cKey);
  if (list) {
    return list;
  }

  return postForm('/api/region/lists').then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      Vue.ls.set(cKey, list, expireTime);
      return list;
    } else {
      return [];
    }
  });
}
*/

// 注册岗位
export function getVolvoRoleOtps () {
  const cKey = 'VOLVO_ROLE';
  return getOtpList(cKey, 'volvo_role');
}
// 人员归属
export function getVolvoAffiliationOtps () {
  const cKey = 'VOLVO_AFFILIATE';
  return getOtpList(cKey, 'volvo_affiliate');
}

// 省份
export function getProvinceOtps () {
  const cKey = 'PROVINCE_OPTS';
  return getOtpList(cKey, 'province');
}

// 集团


// 品牌
export function getBrandOtps () {
  const cKey = 'BRAND_OPTS';
  return getOtpList(cKey, 'brand');
}

// 公司
export function getCompanyOtps () {
  const cKey = 'COMPANY_OPTS';
  const auth = 1; //location.hash.indexOf('system') >= 0 ? 0 : 1;
  return getOtpList(cKey, 'company', auth);
}

// 部门
export function getDepartmentOtps () {
  const cKey = 'DEPARTMENT_OPTS';
  const auth = 1; //location.hash.indexOf('system') >= 0 ? 0 : 1;
  return getOtpList(cKey, 'department', auth);
}

// 分组
export function getGroupOtps () {
  const cKey = 'GROUP_OPTS';
  const auth = 1; //location.hash.indexOf('system') >= 0 ? 0 : 1;
  return getOtpList(cKey, 'group', auth);
}

// 角色
export function getRoleOtps () {
  const cKey = 'ROLE_OPTS';
  return getOtpList(cKey, 'role');
}

// 用户类型
export function getUserTypeOtps () {
  const cKey = 'USER_TYPE_OPTS';
  return getOtpList(cKey, 'admin_type');
}

// 转写引擎
export function getTransferOtps () {
  const cKey = 'TRANSFER_OPTS';
  return getOtpList(cKey, 'transfer_platform');
}

// 二级指标
export function getTestNameOtps (params) {
  return postForm('/api/option/advise_name2', params).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 三级指标
export function getTestItemOtps (params) {
  return postForm('/api/option/advise_name', params).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 评测类型
export function getTestTypeOtps (params) {
  return postForm('/api/option/advise_title', params).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 建议类型
export function getAdviceTypeOtps () {
  return postForm('/api/option/advise_type').then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 进店情况
export function getEntryStateOtps (params) {
  return postForm('/api/option/in_num', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map((o, i) => formatOption(String(i), o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 意向车型
export function gettargetModel (params) {
  return postForm('/api/option/target_model', params).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 车型
export function getCarModelOtps (params) {
  return postForm('/api/option/target_model', params).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 转写次数
export function getQualityOtps (params) {
  return postForm('api/company/getQuality', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map((o) => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 方言
export function getDialectOtps (params) {
  return fetchGet('api/company/getDialect', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map((o,i) => formatOption(i, o, o));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

export function getOtpList (cKey, table, auth=1) {
  /*
  const list = Vue.ls.get(cKey);
  if (list) {
    return list;
  }
  */

  return postForm('/api/option/index', {table, auth}).then(res => {
    if (res.status === 1) {
      const list = (res.data || []);
      if (table === 'company') {
        return list.map(o => ({
          key: String(o.id), value: o.id,
          label: o.name, title: o.name,
          code: o.code
        }));
      }
      if (table === 'field') {
        return list.map(o => formatOption(o.code, o.code, o.name));
      }
      return list.map(o => formatOption(o.id, o.id, o.name));
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 城市选项byId
export function getCityOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/index', {
    table: 'city',
    ...params
  }).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 品牌选项byId
export function getBrandOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/index', {
    table: 'brand',
    ...params
  }).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 铭牌
export function snListsList () {
  return postForm('/api/admin/snLists').then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.sn, o.sn, o.sn));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 试乘试驾
export function getDriveOtps (params) {
  return postForm('/api/option/is_this_test_drive', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map((o, i) => formatOption(String(i), o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 大区选项byId
export function getRegionOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/index', {
    table: 'region',
    ...params
  }).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 小区选项byId
export function getAreaOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/index', {
    table: 'area',
    ...params
  }).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 公司选项byId
export function getCompanyOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/index', {
    table: 'company',
    ...params
  }).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 部门选项byId
export function getDepartmentOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/department', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 分组选项byId
export function getGroupOtpsById (params) {
  params.auth = 1;
  return postForm('/api/option/group', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}


// 成交管理
export function getDealQueryOtps (field) {
  return postForm('/api/option/deal', {field}).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}



// 成交编辑管理
export function getDealOtps (field) {
  return postForm('/api/option/deal_edit', {field}).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 跟踪记录
export function getTrackOtps (field) {
  return postForm('/api/option/track', {field}).then(res => {
    if (res.status === 1) {
      const list = formatOptionList(res.data);
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 线索状态
export function getDealStatusOtps () {
  return postForm('/api/option/deal_status').then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 活动类型
export function getActivitiyTypeOtps (params) {
  return postForm('/api/option/getActivityTypeList', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}
// 活动名称
export function getActivityOtps (params) {
  return postForm('/api/option/getActivityList', params).then(res => {
    if (res.status === 1) {
      const list = (res.data || []).map(o => formatOption(o.id, o.id, o.name));
      return list;
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

// 工作台-筛选项
export function getDealItemOtps () {
  return postForm('/api/workstation/filterOptions').then(res => {
    if (res.status === 1) {
      const {dealOptions, userType} = (res.data || {})
      return {
        dealItemOpts: dealOptions.map(o => formatOption(o.id, o.id, o.options)),
        userTypeOpts: userType.map(o => formatOption(o.id, o.id, o.options)),
      };
    } else {
      return {};
    }
  }, res => {
    return [];
  });
}

// 热门类型
export function getHotTypeOtps () {
  const cKey = 'HOT_TYPE_OPTS';
  return getOtpList(cKey, 'hot_type');
}

// 热门标签
export function getHotLabelOtps () {
  const cKey = 'HOT_LABEL_OPTS';
  return getOtpList(cKey, 'hot_label');
}


function formatOptionList(list) {
  if (!list) {
    return [];
  }
  return list.filter(o => o).map((o, i) => formatOption(String(i), o, o));
}
function formatOption(key, value, label) {
  return {
    key: String(key), value, label, title: label
  }
}

export default {
  fetchGet,
  fetchPost,
  postForm,
  getBusinessOtps,
  getRegionOtps,
  getAreaOtps,
  getBrandOtps,
  getCompanyOtps,
  getDepartmentOtps,
  getGroupOtps,
  getRoleOtps,
  getUserTypeOtps,
  getTransferOtps,
  getTestNameOtps,
  getTestItemOtps,
  getTestTypeOtps,
  getAdviceTypeOtps,
  getEntryStateOtps,
  gettargetModel,
  getDealQueryOtps,
  getDealOtps,
  getTrackOtps,
  getDealStatusOtps,
  getDealItemOtps,
  getHotTypeOtps,
  getHotLabelOtps,
  getCarModelOtps,
  getAsBusinessOtps,
  snListsList,
  getDriveOtps,
  getQualityOtps,
  getDialectOtps,
  getActivityOtps,
  getActivitiyTypeOtps,
};
